import ApiProperties from '../../../src/main/resources/application'

export default {
    data: function () {
        return {
            clientDocument: {
                clientId: null,
                banner: {
                    active: false,
                    html: null
                }
            }
        }
    },

    methods: {
        newClient() {
            return {
                clientId: null,
                banner: {
                    active: false,
                    html: null
                }
            }
        },

        getClientDocument(clientId) {
            if (!clientId) {
                return null
            }

            this.$http.get(ApiProperties.api.clientDocument, {pathVars: {clientId}}).then(response => {
                if (response.data!==null) {
                    this.clientDocument = response.data
                }
            }).catch(e => {
                this.errors.push(e);
            });
        }
    },

    created() {
        this.newClient()
    }
}

