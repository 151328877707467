<template>
  <div class="active-leaves">
    <b-modal id="schedule-details-modal" content-class="schedule-details-modal-content" hide-footer size="lg" scrollable :title="getScheduleModalTitle()">
      <table v-if="selectedSchedule!==null && selectedSchedule.type==='Manual'" class="table b-table schedule-details manual-schedule-details">
        <tr>
          <th>DATE</th>
          <th>HOURS LOST</th>
        </tr>
        <tr v-for="entry in selectedSchedule.manualEntries">
          <td>{{formatDate(entry.date)}}</td>
          <td>{{entry.hours}}</td>
        </tr>
      </table>

      <table v-if="selectedSchedule!==null && selectedSchedule.type==='Reduced'" class="table b-table schedule-details reduced-schedule-details">
        <tr>
          <th>DATE</th>
          <th>SUNDAY</th>
          <th>MONDAY</th>
          <th>TUESDAY</th>
          <th>WEDNESDAY</th>
          <th>THURSDAY</th>
          <th>FRIDAY</th>
          <th>SATURDAY</th>
        </tr>
        <tr v-for="entry in selectedSchedule.reducedWorkSchedule">
          <td>{{formatDate(entry.startDate)}}</td>
          <td>{{entry.sundayHours}}</td>
          <td>{{entry.mondayHours}}</td>
          <td>{{entry.tuesdayHours}}</td>
          <td>{{entry.wednesdayHours}}</td>
          <td>{{entry.thursdayHours}}</td>
          <td>{{entry.fridayHours}}</td>
          <td>{{entry.saturdayHours}}</td>
        </tr>
      </table>
    </b-modal>

    <template v-if="isEnabled">
      <div v-if="safeGet(clientDocument,'banner.active', false)" class="client-banner" style="margin-bottom:0 !important;">
        <div v-html="clientDocument.banner.html"></div>
      </div>
      <div style="font-size: .85em;" class="m-2 p-3">
        <select style="padding:5px 10px 5px 5px; margin:0 15px 0 0;" name="filter" v-model="filter" @change="resetSearch" :disabled="isFilterDisabled()">
          <option value="ALL">All Open Leaves</option>
          <option value="APPROVED">All Approved Leaves</option>
          <option value="PENDING">All Pending Leaves</option>x
          <option value="UPCOMING">Employees Starting Leave within 2 Weeks</option>
          <option value="RETURNING">Employees Scheduled to Work within 2 weeks</option>
          <!--        <option value="RECENT">Any Employee who was recently sent a notification</option>-->
        </select>

        <span style="color: #666666;">OR</span>

        <input type="text" v-model.trim="employee.id" style="margin:0 15px" @input="employee.search=false" placeholder="Employee ID" />

        <a v-if="employee.search" href="#" class="btn btn-sm btn-larkin-yellow" @click.prevent="resetEmployee">Clear</a>
        <a v-else href="#" class="btn btn-larkin-yellow btn-sm" @click.prevent="resetSearch">Search</a>
      </div>

      <div class="larkin-enclosure-box">
        <div class="d-flex">
          <b-pagination
              v-if="pagination.show"
              v-model="pagination.page"
              :total-rows="pagination.totalResults"
              :per-page="pagination.pageSize"
              last-number
              :first-text="'\u00AB'"
              :prev-text="'\u2039'"
              :next-text="'\u203A'"
              @change="changePage"
          ></b-pagination>
          <p v-if="pagination.show" class="m-0 ml-2" style="line-height:1.6rem">{{pagination.itemStart}}-{{pagination.itemEnd}} of {{pagination.totalResults}}</p>
        </div>
        <table ref="active-leaves-table" v-if="!searching && employeesWithCombinedSchedules.length" class="table b-table leave-table table-hover active-leaves-table" style="table-layout: fixed;">
          <colgroup>
            <col />
            <col />
            <col />
            <col class="leave-request-date-col" />
            <col class="leave-request-date-col" />
            <col class="leave-request-col" />
          </colgroup>
          <thead role="rowgroup">
          <tr role="row" class="super-header">
            <th colspan="3"></th>
            <th colspan="3" class="header-detail leave-request-data"><div class="bg-block">On Leave</div></th>
          </tr>
          <tr role="row">
            <th>Last Name</th>
            <th>First Name</th>
            <th class="">Employee ID</th>
            <th class="leave-request-date-col">From</th>
            <th class="leave-request-date-col">Through</th>
            <th class="leave-request-col">Leave Details</th>
          </tr>
          </thead>
          <tbody role="rowgroup">
            <tr role="row" tabindex="0" v-for="employee in employeesWithCombinedSchedules" :key="employee.masterEmployeeId">
              <td>{{employee.lastName}}</td>
              <td>{{employee.firstName}}</td>
              <td>{{employee.eid}}</td>
              <td colspan="3" class="schedule-sub-table">
                <template v-for="combined in employee.combinedSchedules">
                  <div class="d-inline-flex flex-row" :class="{ 'manual-reduced-date': isLeaveScheduleType(combined) }" style="align-items: center">
                    <div class="leave-request-date-col">{{combined.fromDate}}</div>
                    <div class="leave-request-date-col">{{combined.throughDate}}</div>

                    <!-- leave date block only -->
                    <div v-if="isLeaveDateBlockType(combined)" class="leave-request-col leave-schedules">
                      <p v-for="schedule in combined.dateBlockSchedules">{{safeGet(schedule, 'leaveCoverageType.name', 'N/A')}} ({{displayStatus(safeGet(schedule,'status.name', 'N/A'))}}): {{formatDate(schedule.fromDate) || dateUnavailablePlaceholder()}} - {{formatDate(schedule.throughDate) || dateUnavailablePlaceholder()}}</p>
                    </div>

                    <!-- leave schedule only -->
                    <div v-if="isLeaveScheduleType(combined)" class="leave-request-col">
                      {{combined.coverageDetailName}} ({{displayStatus(combined.schedule.status.name)}}): <br /><strong style="font-size:.9em">* On leave for portions of the day or working a reduced schedule, for more details <a href="#" @click.prevent="openLeaveScheduleDetails(combined.schedule)">click here</a></strong>
                    </div>
                  </div>
                </template>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else><h6 v-if="!searching">You don't have any employees on leave or no employee leaves match your selected filter.</h6></div>
      </div>
    </template>
    <template v-else>
      <h1 class="text-center">Coming Soon</h1>
    </template>
  </div>
</template>
<script>
import ApiProperties from '../../../src/main/resources/application'
import dateMixin from '../mixins/dateMixin.js';
import leaveMixin from "@/mixins/leaveMixin";
import featureBit from "@/mixins/featureBit";
import clientMixin from "@/mixins/clientMixin";
import get from "just-safe-get";

const WEEKS_FOR_RETURNING_UPCOMING = 2
const STATUS = {
  APPROVED: ["Approved", "Approved Done"],
  PENDING: ["Pending"],
}

//Report type does NOT include statuses, they are dealt with separately.
const REPORT_TYPE = {
  UPCOMING: "UPCOMING",
  RETURNING: "RETURNING",
  RECENT: "RECENT"
}

const LEAVE_DATE_BLOCK_TYPE = "LeaveDateBlock"
const LEAVE_SCHEDULE_TYPE = "LeaveSchedule"

export default {
  name: 'activeLeaves',
  data() {
    return {
      safeGet: get,
      isEnabled: true,
      employee: {},
      selectedSchedule: null,
      searching: false,
      employeesWithCombinedSchedules: [],
      filter: "ALL",
      sort: {
        'lastName': 'ascending',
        'firstName': null
      },
      pagination: {
        show: false,
        page: 1,
        pageSize: 150,
        itemStart: 1,
        itemEnd: 150,
        totalResults: 0
      }
    }
  },
  props: ['employeeId'],
  mixins: [dateMixin, leaveMixin, featureBit, clientMixin],
  methods: {
    isFilterDisabled() {
      return (this.employee && this.employee.id && this.employee.id.trim().length !== 0)
    },

    displayStatus(status) {
      if (status==="Approved Done") {
        return "Approved"
      }

      return status
    },

    isLeaveDateBlockType(combined) {
      return combined.type===LEAVE_DATE_BLOCK_TYPE
    },

    isLeaveScheduleType(schedule) {
      return schedule.type===LEAVE_SCHEDULE_TYPE
    },

    setPagination(page, pageSize, totalResults) {
      this.pagination.page = page
      this.pagination.pageSize = pageSize
      this.pagination.totalResults = totalResults
      this.pagination.show = !(this.pagination.page<=1 && this.pagination.totalResults < this.pagination.pageSize)
      this.pagination.itemStart = ((page-1) * pageSize) + 1

      let itemEnd = (this.pagination.itemStart + pageSize) - 1
      this.pagination.itemEnd = (itemEnd > totalResults) ? totalResults : itemEnd
    },

    changePage(page) {
      this.setPagination(page, this.pagination.pageSize, this.pagination.totalResults)
      this.searchActiveLeaves()
    },

    getScheduleModalTitle() {
      let type = get(this, "selectedSchedule.type")
      let fromDate = get(this, "selectedSchedule.fromDate")
      let throughDate = get(this, "selectedSchedule.throughDate")
      return `${type || this.dateUnavailablePlaceholder()} Schedule (${fromDate || this.dateUnavailablePlaceholder()} - ${throughDate || this.dateUnavailablePlaceholder()})`
    },

    openLeaveScheduleDetails(schedule) {
      if (schedule!==null) {
        this.selectedSchedule = schedule
        this.$bvModal.show('schedule-details-modal');
      }
    },

    getRequestFromFilter() {
      let dateRange=null,
          reportType = null,
          statuses = Object.keys(STATUS).flatMap(key => STATUS[key])

      if (this.employee && this.employee.id) {
        this.filter="ALL"
      }

      if (Object.keys(REPORT_TYPE).includes(this.filter)) {
        let today = new Date();
        dateRange = {
          from: today,
          to: this.getWeeksFromToday(WEEKS_FOR_RETURNING_UPCOMING)
        }
        reportType = REPORT_TYPE[this.filter]
      }

      if (Object.keys(STATUS).includes(this.filter)) {
        statuses = STATUS[this.filter]
      }

      if (get(this, 'employee.id', null)==="") {
        this.employee = {}
      }

      return {
        employeeId: this.employee && this.employee.id,
        statuses,
        reportType,
        dateRange,
        page: this.pagination.page,
        pageSize: this.pagination.pageSize
      }
    },

     clearPreviousSort: function(sortBy) {
      if (sortBy==='firstName') {
        this.sort.lastName = null
      } else if (sortBy==='lastName') {
        this.sort.firstName = null
      }
    },

    sortEmployeeStringBy: function(sortBy, toggleSort) {
      function compare(a, b) {
        if ( a.employee[sortBy].toLowerCase() < b.employee[sortBy].toLowerCase() ){
          return -1;
        }
        if ( a.employee[sortBy].toLowerCase() > b.employee[sortBy].toLowerCase() ){
          return 1;
        }
        return 0;
      }

      this.clearPreviousSort(sortBy)

      if (toggleSort) {
        this.sort[sortBy] = (this.sort[sortBy]==='ascending') ? 'descending' : 'ascending';
      }

      (this.sort[sortBy]==="ascending") ? this.activeLeaves.sort(compare) : this.activeLeaves.sort(compare).reverse()
    },

    setEmployee(opt) {
      this.employee = {
        id: get(opt, 'id', null),
        search: get(opt, 'search', false)
      }
    },

    resetEmployee() {
      this.setEmployee()
    },

    resetSearch() {
      this.setPagination(1, 150, 0)
      this.searchActiveLeaves()
    },

    searchActiveLeaves() {
      this.searching=true
      let payload = this.getRequestFromFilter()

      this.$http.post(ApiProperties.api.activeLeaves, payload).then(response => {
        this.setEmployee({
          id: get(payload, 'employeeId', null),
          search: (payload.employeeId!==null)
        })
        this.filter = (get(this, "employee.search", false)) ? "ALL" : this.filter
        let data = response.data
        this.setPagination(data.page, data.pageSize, data.totalResults)
        this.setEmployeesWithCombinedSchedules(data.results)
        this.searching=false
      }).catch(e => {
        this.errors.push(e);
        this.searching=false
      })
    },

    /**
     * Takes Leave Date Blocks and Manual/Reduced leave schedules and combines them so they can be sorted by fromDate
     *
     * @param employeesWithLeaveBlocksAndManualReducedLeaves
     */
    setEmployeesWithCombinedSchedules(employeesWithLeaveBlocksAndManualReducedLeaves) {
      this.employeesWithCombinedSchedules = []

      for (const employee of employeesWithLeaveBlocksAndManualReducedLeaves) {
        let combinedSchedules = []

        if (employee.leaveDateBlocks!==null) {
          for (const leaveDateBlock of employee.leaveDateBlocks) {
            combinedSchedules.push({
              type: LEAVE_DATE_BLOCK_TYPE,
              fromDate: this.formatDate(leaveDateBlock.fromDate),
              throughDate: this.formatDate(leaveDateBlock.throughDate) || this.dateUnavailablePlaceholder(),
              dateBlockSchedules: leaveDateBlock.schedules
            })
          }
        }

        if (employee.leaves!==null) {
          for (const leave of employee.leaves) {
            if (leave.leaveSchedule!==null) {
              for (const leaveSchedule of leave.leaveSchedule) {
                    combinedSchedules.push({
                      type: LEAVE_SCHEDULE_TYPE,
                      fromDate: this.formatDate(leaveSchedule.fromDate),
                      throughDate: this.formatDate(leaveSchedule.throughDate) || this.dateUnavailablePlaceholder(),
                      coverageDetailName: leave.coverageDetail.name,
                      schedule: leaveSchedule
                    })
              }
            }
          }
        }

        combinedSchedules.sort(function(a,b) {
          return new Date(a.fromDate) - new Date(b.fromDate)
        })

        this.employeesWithCombinedSchedules.push({
          masterEmployeeId: employee.masterEmployeeId,
          firstName: employee.firstName,
          lastName: employee.lastName,
          eid: employee.eid,
          combinedSchedules
        })
      }
    }
  },

  created() {
    let _self = this
    this.isFeatureEnabled("ActiveLeaves", (data) => {
      _self.isEnabled = data

      if (!data) {
        return
      }

      _self.setEmployee({
        id: this.employeeId
      })

      if (this.employeeId===null) {
        _self.filter = REPORT_TYPE.UPCOMING
      }
      _self.searchActiveLeaves()
    })

    this.getClientDocument(get(this, '$root.user.employerId', null))
  }
}
</script>
<style lang="scss">
  @import '../scss/leave-table.scss';

  #schedule-details-modal {
    .modal-body {
      padding-left: 75px;
      padding-right: 75px;
    }

    .schedule-details {
      tr {
        td,th {
          font-size: .8rem;
          padding:5px 0;
          text-align: center;
        }

        th {
          background-color: #e0e0e0;
        }

        th:first-child {
          background-color: #9b9b9b;
        }

        td:first-child {
          background-color: #e0e0e0;
        }
      }
    }
  }

  .active-leaves {
    .client-banner {
      font-size: .85em;
      margin: 25px;
      padding:25px;
      border: 1px solid #e1e1e1;
      background: #fff;

      strong {
        font-weight: 900;
      }

      ul {
        margin:0;
        li {
          margin:0;
        }
      }

      p {
        margin:0;
      }

      .ql-align-center {
        text-align: center;
      }

      .ql-align-right {
        text-align: right;
      }


    }

    .larkin-enclosure-box {
      min-width: 1270px;
    }

    .active-leaves-table {
      table-layout: fixed !important;
      width: 100%;

      tbody, thead {
        tr {
          th, td, td p {
            font-size: .8rem !important;
          }
        }
      }

      .leave-request-data {
        width: 810px;
      }

      .leave-request-col {
        width: 550px;
        padding-left:5px;
        padding-right:0;
      }

      .leave-request-date-col {
        width:130px;
        padding-left:5px;
        padding-right:0;
      }

      .manual-reduced-date {
        .leave-request-date-col {
          font-weight: 400;
        }

        .leave-request-date-col:first-child {
          &:before {
            content: "* ";
            margin-left: -10px;
          }
        }
      }

      .leave-schedules {
        p {
          margin:1px 0;
          padding:0;
        }
      }

      .super-header {
        th {
          border:0 !important;
          padding-left: 0 !important;
          padding-right: 0 !important;
        }

        th.header-detail {
          font-size: .7em !important;
          font-weight: bold !important;
          text-align: center;
          margin-left: -5px;

          .bg-block {
            width: 100%;
            margin: 0 10px 0 0;
            padding: 5px 0;
            color: #333 !important;
            background-color: #ECE7E0;
          }
        }
      }

      th[class^='leave-col*'], th[class^='leaveschedule-col*'] {
        padding-left:0 !important;
        padding-right:0 !important;
      }
    }
  }
</style>
