import ApiProperties from '../../../src/main/resources/application'

export default {
    data() {
        return {
            endpoint: "/rest/feature"
        }
    },

    methods: {
        isFeatureEnabled(featureName, setFn) {
            this.$http.get(ApiProperties.api.featureEnabled, { pathVars: {featureName: featureName}}).then(response => {
                setFn(response.data)
            }).catch(e=> {
                setFn(true)
            })
        },

        areFeaturesEnabled(features, setFn) {
            this.$http.post(ApiProperties.api.featuresEnabled, features).then(response => {
                setFn(response.data)
            }).catch(e=> {
                //fake the response and show none if exception exists
                let data = {}
                for (let feature in features) {
                    data[feature] = false
                }

                setFn(data)
            })
        }
    }
}